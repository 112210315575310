<template>
  <div class="header__wrapper">
    <!--<AuthModal :show="isShowAuth" @close="() => {
      this.isShowAuth = false;
    }" @ok="setToken($event)"/> -->
    <div class="header__container">
      <div v-if="!companyId">
        <logo
          style="cursor: pointer"
          @click="redirectTo('/')"
          v-if="width > t_lg"/>
        <img src="@/assets/pictures/header/smallLogo.png" @click="redirectTo('/')"
             v-if="width <= t_lg"
             class="header__logo" />
      </div>
      <div v-if="companyId === 'b59d73af0dc8fed545046314dc144f81'">
        <img src="@/assets/pictures/kit/innocam.png" @click="redirectTo('/')"
             class="header__logo" />
      </div>
      <div class="block__flex block__center block__align-center">
        <div v-if="user && !companyId" style="margin-left: 20px">
          <a @click="pushToLk">
            <Button
              :text="header.personal_account[language]"
              v-if="width > t_lg" />
          </a>
        </div>
      </div>
      <div>
        <div class="block block__end block__align-center">
          <div
            v-if="width > t_lg && user"
            class="header__user_wrapper block__flex block__align-center">
            <Dropdown>
              <template slot="body">
                <div class="block__flex block__align-center">
                  <div style="text-align: right">
                    <div v-if="user.company_name" style="font-weight: 600;">
                      {{user.company_name}}
                    </div>
                    <div v-if="user.email" style="font-weight: 600;">
                      {{user.email}}
                    </div>
                  </div>
                  <div class="header__user_logo" style="margin-left: 10px">
                    <div class="header__user_container">
                      <a-icon type="user" style="color: white" />
                    </div>
                  </div>
                </div>
              </template>
              <template slot="dropdown">
                <div class="header__user_signout" @click="signOut">
                  {{header.sign_out[language]}}
                </div>
              </template>
            </Dropdown>
          </div>
          <Button
            type="primary"
            :text="header.sign_in[language]"
            v-if="width > t_lg && !user"
            class="component__margin-left"
            @click="showAuthModal" />
          <Dropdown class="header__flag_wrapper" :active="activeFlag">
            <template slot="body">
              <div class="header__flag_container" @click="e => e.preventDefault()">
                <div style="height: 38px">
                  <Ru
                    v-if="language === 'ru' && width > t_lg"
                    height="36"
                    class="header__flags_wrapper" />
                  <Gb
                    v-if="language === 'en' && width > t_lg"
                    class="header__flags_wrapper"
                    height="36" />
                  <div v-if="language === 'ru' && width <= t_lg" class="header__language">Ru</div>
                  <div v-if="language === 'en' && width <= t_lg" class="header__language">En</div>
                </div>
              </div>
            </template>
            <template slot="dropdown">
              <div
                class="header__language_wrapper"
                @click="setLanguage(lang.id)"
                v-for="lang in languages" v-bind:key="`lang_${lang.id}`">
                <div v-if="lang.name === 'ru'">
                  <Ru v-if="width > t_lg" height="36" class="header__flags_wrapper" />
                  <div v-if="width <= t_lg" class="header__language">Ru</div>
                </div>
                <div v-if="lang.name === 'en'">
                  <Gb v-if="width > t_lg" height="36" class="header__flags_wrapper" />
                  <div v-if="width <= t_lg" class="header__language">En</div>
                </div>
              </div>
            </template>
          </Dropdown>
          <Burger class="header__burger" v-if="width <= t_lg" @click="showMenu" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import header from '@/assets/text/header.json';
import Burger from '@/assets/pictures/header/Burger.svg';
import { query } from '@/utilites/axios';

const Button = () => import('./HeaderButton.vue');
// const AuthModal = () => import('./AuthModal.vue');
const Dropdown = () => import('@/components/dropdown/Dropdown.vue');
const Logo = () => import('@/assets/pictures/i5/wide_logo.svg');
const Ru = () => import('@/assets/pictures/flags/ru.svg');
const Gb = () => import('@/assets/pictures/flags/gb.svg');

export default {
  name: 'Header',
  data() {
    return {
      header,
      isShowAuth: false,
      token: null,
      activeFlag: null,
      languages: [],
    };
  },
  computed: {
    t_lg() {
      return this.$store.state.index.t_lg;
    },
    language() {
      return this.$store.state.index.language;
    },
    width() {
      return this.$store.state.index.width;
    },
    user() {
      return this.$store.state.user.user;
    },
    companyId() {
      return this.$store.state.index.companyId;
    },
  },
  methods: {
    showAuthModal() {
      // this.isShowAuth = true;
      this.$store.commit('user/setShowAuthorizeModal', true);
      this.$store.commit('user/setAfterAuthorize', () => {
        this.$router.push('/orders/draft');
      });
    },
    setLanguage(id) {
      const index = this.languages.findIndex((el) => el.id === id);
      const setLang = () => {
        const lang = this.languages[index].name;
        this.$store.commit('index/setLanguage', lang);
        localStorage.setItem('language', lang);
        this.activeFlag = lang;
      };
      if (index >= 0) {
        const token = localStorage.getItem('token');
        if (token) {
          query('post', 'changeUserInfoByUser', {
            lang: id,
          }, {
            token: token,
          }).then(() => {
            setLang();
          });
        } else {
          setLang();
        }
      }
    },
    showMenu() {
      this.$emit('showMenu');
    },
    pushToLk() {
      this.$router.push('/orders/draft');
    },
    redirectTo(path) {
      if (path !== this.$router.history.current.path) {
        this.$router.push(path);
      }
    },
    setToken(token) {
      this.token = token;
    },
    signOut() {
      this.$store.commit('user/removeUser');
      this.$store.commit('user/removeAccess');
      localStorage.removeItem('token');
      this.token = null;
    },
    getLanguages() {
      query('post', 'getLanguages', {}, {}).then((res) => {
        this.languages = res;
      });
    },
  },
  created() {
    this.getLanguages();
    const secretKey = localStorage.getItem('token');
    if (secretKey) {
      this.token = secretKey;
    }
  },
  components: {
    Burger,
    Button,
    // AuthModal,
    Dropdown,
    Logo,
    Ru,
    Gb,
  },
};
</script>
